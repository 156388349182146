import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import PageHeader from '~/components/PageHeader';
import { DownloadWord } from '~/gatsby-theme-docz/components/DownloadWord';
import Wordbilde from './Officemaler_Entur.png';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader"></PageHeader>
    <DownloadWord mdxType="DownloadWord"></DownloadWord>
    <img src={Wordbilde} width="100%" alt="Eksempler hvor Entur sin word-mal er brukt" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      